<template>
  <div class="mt-0-px pb-0">
    <div id="etf" v-if="angemeldeterMitarbeiter">
      <navigation-arvea :angemeldeterMitarbeiter="angemeldeterMitarbeiter" />
      <!-- BANNER 
      <div class="row banner">
        <div class="col-3"></div>
        <div class="col-6">
          <h1>ARVEA Universe ETF</h1>
        </div>
        <div class="col-3">
          <img src="https://arvea-dev.batix.ch/pic/183F4886830_241x241.png" />
        </div>
      </div>-->

      <!-- GRAPH -->
      <loading-overlay v-show="loading"> </loading-overlay>
      <div
        v-if="!loading"
        class="row etfGraph mb-5 px-5 d-flex justify-content-center etfGraphRow"
      >
        <div class="col-xl-3 d-flex flex-column p-0">
          <div class="title mb-5">
            <h1>Exchange Traded Fund</h1>
            <!--<span>Untertitel</span>-->
          </div>
          <div class="">
            <div class="form-group etfDropdown mb-3">
              <v-select
                :value="pair.pair"
                :options="pairsOptions"
                @input="handleNewPair"
                placeholder="Pair"
                :clearable="false"
                name="pairs"
              >
                <span slot="no-options"> Keine Optionen </span>
              </v-select>
              <label for="pairs">Pairs</label>
            </div>
          </div>
          <div class="">
            <div class="form-group etfDropdown mb-3">
              <v-select
                v-model="max"
                label="Days"
                :options="days"
                placeholder="27"
                :clearable="false"
                name="days"
                @input="trackSelects('days')"
              >
                <span slot="no-options"> Keine Optionen </span>
              </v-select>
              <label for="days">Days</label>
            </div>
          </div>
          <div class="etfInfos">
            <div class="etfSymbols">
              {{ this.pair.pair }}
            </div>
            <div class="etfDates">
              {{ this.datacollection.datasets[0].datum[0] }} -
              {{
                this.datacollection.datasets[0].datum[
                  this.datacollection.datasets[0].datum.length - 1
                ]
              }}
            </div>
          </div>
        </div>
        <div class="col-xl-7 etfGraph_graph">
          <etf-chart :datacollection="datacollection" :key="etfChartKey" />
        </div>
      </div>

      <!-- Strike Evolution Graphs -->
      <!--div class="strikeEvolutionGraphs row m-auto" style="max-width: 85%">
        <div class="col-xl-6">
          <strike-evolution-graph
            v-if="!isBusy && strikes[0].length > 0"
            :title="'Strike Evolution - ' + this.pair.symbol1"
            type="SE"
            :yMaxStrikePrice="strikes[0][strikes[0].length - 1].yMaxStrikePrice"
            :yMinStrikePrice="strikes[0][strikes[0].length - 1].yMinStrikePrice"
            :strikeEvolution="strikes[0]"
            :key="'SEGraph1-' + updateKey"
          ></strike-evolution-graph>
        </div>
        <div class="col-xl-6">
          <strike-evolution-graph
            v-if="!isBusy && strikes[1].length > 0"
            :title="'Strike Evolution - ' + this.pair.symbol2"
            type="SE"
            :yMaxStrikePrice="strikes[1][strikes[1].length - 1].yMaxStrikePrice"
            :yMinStrikePrice="strikes[1][strikes[1].length - 1].yMinStrikePrice"
            :strikeEvolution="strikes[1]"
            :key="'SEGraph2-' + updateKey"
          ></strike-evolution-graph>
        </div>
      </div-->

      <!--
      <div class="row m-auto" style="max-width: 85%">
        <div class="col-xl-12 d-flex align-items-center">
          <div class="row col-xl-12">
            <div class="col-xl-6 my-5">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-select
                      :value="pair.pair"
                      :options="pairsOptions"
                      @input="setGraphData"
                      placeholder="Pair"
                      :clearable="false"
                    >
                      <span slot="no-options"> Keine Optionen </span>
                    </v-select>
                    <label>Pairs</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-select
                      v-model="max"
                      label="Days"
                      :options="days"
                      placeholder="27"
                      :clearable="false"
                    >
                      <span slot="no-options"> Keine Optionen </span>
                    </v-select>
                    <label>Days</label>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                col-xl-12
                my-5
                px-5
                d-flex
                justify-content-center
                align-items-center
              "
            >
              <div class="col-xl-6">
                <etf-chart
                  :datacollection="datacollection"
                  :key="etfChartKey"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
-->
      <!-- TABLE -->
      <div class="row m-auto" style="max-width: 85%" v-if="!loading">
        <div class="col-xl-12 d-flex align-items-center">
          <div class="row col-xl-12">
            <div class="col-xl-12 my-5">
              <div class="scrollable-table">
                <b-table
                  class="etfTable"
                  ref="selectableTable"
                  tbody-tr-class="item"
                  :items="list"
                  :fields="listFields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :sort-compare="sortDates"
                  :busy="isBusy"
                  striped
                  hover
                  sort-icon-left
                  fixed
                  selectable
                  borderless
                  select-mode="multi"
                  @row-clicked="handleRowClicked"
                >
                  <template #head()="{ label, field: { key } }">
                    {{ label }}
                    <template>
                      <svg
                        v-if="sortBy !== key"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        style="
                          width: 0.65rem;
                          height: 1rem;
                          fill: #213056;
                          margin: 0 0 3px 5px;
                        "
                      >
                        <path
                          d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"
                        />
                      </svg>
                      <svg
                        v-else-if="
                          (key != 'symbol' && sortDesc) ||
                          (key == 'symbol' && !sortDesc)
                        "
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        style="
                          width: 0.65rem;
                          height: 1rem;
                          fill: #213056;
                          margin: -7px 0 0 5px;
                        "
                      >
                        <path
                          d="M311.9 335.1l-132.4 136.8C174.1 477.3 167.1 480 160 480c-7.055 0-14.12-2.702-19.47-8.109l-132.4-136.8C-9.229 317.8 3.055 288 27.66 288h264.7C316.9 288 329.2 317.8 311.9 335.1z"
                        />
                      </svg>
                      <svg
                        v-else-if="key == 'symbol' && sortDesc"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        style="
                          width: 0.65rem;
                          height: 1rem;
                          fill: #213056;
                          margin: 0 0 0 5px;
                        "
                      >
                        <path
                          d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224z"
                        />
                      </svg>
                      <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        style="
                          width: 0.65rem;
                          height: 1rem;
                          fill: #213056;
                          margin: 0 0 0 5px;
                        "
                      >
                        <path
                          d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224z"
                        />
                      </svg>
                    </template>
                  </template>
                  <template #row-details="row">
                    <div class="scrollable-details-table">
                      <b-table
                        ref="table"
                        tbody-tr-class="item"
                        :items="row.item.dates"
                        :fields="allOpenRowsFields"
                        :sort-by.sync="detailSortBy"
                        :sort-desc.sync="detailSortDesc"
                        :sort-compare="sortDates"
                        :busy="isBusy"
                        show-empty
                        sort-icon-left
                        fixed
                        selectable
                        borderless
                        select-mode="range"
                        class="detailTable"
                        @sorted="handleTableSorted"
                      >
                        <template #head()="{ label, field: { key } }">
                          {{
                            label == "Symbol 1"
                              ? row.item.symbol1
                              : label == "Symbol 2"
                              ? row.item.symbol2
                              : label
                          }}
                          <template>
                            <svg
                              v-if="detailSortBy !== key"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                              style="
                                width: 0.65rem;
                                height: 1rem;
                                fill: #213056;
                                margin: 0 0 3px 5px;
                              "
                            >
                              <path
                                d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"
                              />
                            </svg>
                            <svg
                              v-else-if="detailSortDesc"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                              style="
                                width: 0.65rem;
                                height: 1rem;
                                fill: #213056;
                                margin: -7px 0 0 5px;
                              "
                            >
                              <path
                                d="M311.9 335.1l-132.4 136.8C174.1 477.3 167.1 480 160 480c-7.055 0-14.12-2.702-19.47-8.109l-132.4-136.8C-9.229 317.8 3.055 288 27.66 288h264.7C316.9 288 329.2 317.8 311.9 335.1z"
                              />
                            </svg>
                            <svg
                              v-else
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                              style="
                                width: 0.65rem;
                                height: 1rem;
                                fill: #213056;
                                margin: 0 0 0 5px;
                              "
                            >
                              <path
                                d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224z"
                              />
                            </svg>
                          </template>
                        </template>
                      </b-table>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import page from "@/mixins/Page";
import store from "@/store";
import Api from "@/Api";
import EtfChart from "../components/charts/EtfChart.vue";
import LoadingOverlay from "@/components/global/LoadingOverlay";
import NavigationArvea from "@/components/Navigation/NavigationArvea";
import Arvea from "@/Arvea";
import StrikeEvolutionGraph from "@/components/charts/StrikeEvolutionGraph";

export default {
  name: "ETF",
  components: {
    EtfChart,
    LoadingOverlay,
    NavigationArvea,
    //StrikeEvolutionGraph,
  },
  mixins: [page],
  store,
  metaInfo() {
    return {
      titleTemplate: () => {
        return "Arvea Universe ETF";
      },
    };
  },
  data() {
    return {
      clicks: [],
      expandedRows: [],
      page: 0,
      loading: false,
      max: 27,
      pair: {},
      days: [],
      anzahlProPage: 100,
      list: [],
      pairsOptions: [],
      allOpenRows: [],
      ladeFehler: false,
      infiniteId: +new Date(),
      sortBy: "",
      sortDesc: true,
      detailSortBy: "date",
      detailSortDesc: true,
      sortDirection: "desc",
      isBusy: false,
      startDates: [],
      etfChartKey: 0,
      datacollection: {
        labels: [
          27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11,
          10, 9, 8, 7, 6, 5, 4, 3, 2, 1,
        ],
        datasets: [
          {
            data: [],
            inverseData: [],
            label: "Datenreihe n1",
            borderColor: "#3e95cd",
            fill: false,
            datum: [],
          },
        ],
      },
      allOpenRowsFields: [
        {
          key: "date",
          sortable: true,
          label: "Date",
          variant: "arvea-medium",
        },
        {
          key: "regularmarketprice1",
          sortable: true,
          label: "Symbol 1",
          class: "text-right",
          variant: "arvea-medium",
          formatter: (value) => {
            return this.formatNum(value);
          },
        },
        {
          key: "regularmarketprice2",
          sortable: true,
          label: "Symbol 2",
          class: "text-right",
          variant: "arvea-medium",
          formatter: (value) => {
            return this.formatNum(value);
          },
        },
        {
          key: "value",
          sortable: true,
          label: "Value",
          class: "text-right",
          variant: "arvea-medium",
          formatter: (value) => {
            return this.formatNum(value);
          },
        },
        {
          key: "values[3].zvalue",
          sortable: true,
          label: "27",
          class: "text-right",
          thClass: "table-arvea-medium",
          tdClass: (value) => {
            return this.getGreen(value);
          },
          formatter: (value) => {
            return this.formatNum(value);
          },
        },
        {
          key: "values[2].zvalue",
          sortable: true,
          label: "36",
          class: "text-right",
          thClass: "table-arvea-medium",
          tdClass: (value) => {
            return this.getGreen(value);
          },
          formatter: (value) => {
            return this.formatNum(value);
          },
        },
        {
          key: "values[1].zvalue",
          sortable: true,
          label: "45",
          class: "text-right",
          thClass: "table-arvea-medium",
          tdClass: (value) => {
            return this.getGreen(value);
          },
          formatter: (value) => {
            return this.formatNum(value);
          },
        },
        {
          key: "values[0].zvalue",
          sortable: true,
          label: "54",
          class: "text-right",
          thClass: "table-arvea-medium",
          tdClass: (value) => {
            return this.getGreen(value);
          },
          formatter: (value) => {
            return this.formatNum(value);
          },
        },
      ],
      listFields: [
        {
          key: "pair",
          sortable: true,
          label: "Pair",
        },
        { key: "pairType", sortable: true, label: "Type" },
        {
          key: "dates[0].values[3].zvalue",
          sortable: true,
          label: "27",
          class: "text-right",
          tdClass: (value) => {
            return this.listGetGreen(value);
          },
          formatter: (value) => {
            return this.formatNum(value);
          },
        },
        {
          key: "dates[0].values[2].zvalue",
          sortable: true,
          label: "36",
          class: "text-right",
          tdClass: (value) => {
            return this.listGetGreen(value);
          },
          formatter: (value) => {
            return this.formatNum(value);
          },
        },
        {
          key: "dates[0].values[1].zvalue",
          sortable: true,
          label: "45",
          class: "text-right",
          tdClass: (value) => {
            return this.listGetGreen(value);
          },
          formatter: (value) => {
            return this.formatNum(value);
          },
        },
        {
          key: "dates[0].values[0].zvalue",
          sortable: true,
          label: "54",
          class: "text-right",
          tdClass: (value) => {
            return this.listGetGreen(value);
          },
          formatter: (value) => {
            return this.formatNum(value);
          },
        },
      ],
      strikes: [
        /* [{ yMaxStrikePrice: 0, yMinStrikePrice: 0 }],
        [{ yMaxStrikePrice: 0, yMinStrikePrice: 0 }], */
      ],
      updateKey: "",
      counter: 0,
    };
  },
  watch: {
    max: function (value) {
      if (value) {
        this.datacollection.labels = this.range(value, 1);
        this.assignSelectedPair();
        this.setYAxes();
        this.etfChartKey++;
      }
    },
    pair: function (value) {
      if (value) {
        //this.showStrikeEvolutions();
        this.assignSelectedPair(value);
        this.setYAxes();
        this.etfChartKey++;
      }
    },
  },
  computed: {
    permissions() {
      return this.$store.state.permissions;
    },
    startdatum: function () {
      let dates = this.datacollection.datasets[0].datum;

      /*      console.log(
        this.datacollection.datasets[0].datum.reduce((a, b) => (a > b ? a : b))
      ); */
      return this.datacollection.datasets[0].datum.reduce((a, b) =>
        a > b ? a : b
      );
    },
    enddatum: function () {
      /*      console.log(
        this.datacollection.datasets[0].datum.reduce((a, b) => (a < b ? a : b))
      ); */
      return this.datacollection.datasets[0].datum.reduce((a, b) =>
        a < b ? a : b
      );
    },
  },
  created() {},
  mounted() {
    setInterval(this.sendClicks.bind(this, false), 60000);
    this.get();
  },

  methods: {
    setYAxes() {
      let maxValue = 3.0;
      let minValue = -3.0;

      this.datacollection?.datasets?.forEach((dataset) => {
        let datasetMax = 0;
        let datasetMin = 0;
        /*Array bereinigt, falls Werte undefined oder null sind, da sonst Math.max/min.apply nicht
        richtig funktioniert*/
        let cleanedDataset = [];

        dataset.data.forEach((data, index) => {
          if (data) {
            data = data - data * 2;
            cleanedDataset.push(data);
            dataset.data[index] = data;
          }
        });

        datasetMax = Math.max.apply(Math, cleanedDataset);
        datasetMin = Math.min.apply(Math, cleanedDataset);

        if (datasetMax > maxValue) {
          maxValue = Math.ceil(datasetMax / 0.5) * 0.5;
        }
        if (datasetMin < minValue) {
          minValue = Math.floor(datasetMin / 0.5) * 0.5;
        }
      });
      this.datacollection.yMax = maxValue;
      this.datacollection.yMin = minValue;
    },
    assignSelectedPair(pair = this.pair) {
      let selectedPair = {
        data: this.getData(pair.dates, true), //this.getData(pair.dates, true)
        label: pair.label,
        borderColor: pair.borderColor,
        fill: pair.fill,
        datum: pair.dates
          .slice(0, this.max)
          .map((i) => i.date)
          .reverse(),
      };

      let json = Object.assign({}, selectedPair);
      this.datacollection.datasets = [json];
    },
    range(size, startAt = 0) {
      return [...Array(size).keys()].map((i) => i + startAt).reverse();
    },
    getData(dates, reverse = false) {
      if (reverse) {
        return dates
            .slice(0, this.max)
            .map((i) => i.values?.find((value) => value.max == this.max)?.zvalue)
            ?.reverse();
      } else {
        return dates
            .slice(0, this.max)
            .map((i) => i.values?.find((value) => value.max == this.max)?.zvalue);
      }
    },
    get() {
      this.loading = true;
      Api.get("etf/", {})
        .then((response) => {
          this.list = response.data.list;
          this.list.forEach((li) => {
            this.pairsOptions.push(li.pair);
          });
          function unique(value, index, self) {
            return self.indexOf(value) === index;
          }
          this.pairsOptions = this.pairsOptions.filter(unique);
          this.startDates = response.data.startDates;
          this.days = response.data.days;
          this.pair = this.list.length > 0 ? this.list[0] : {};

          // set Initial Click to compare first Userclick to
          this.clicks.push({
            user_ID: this.angemeldeterMitarbeiter.mitarbeiter.id,
            user_name:
              this.angemeldeterMitarbeiter.mitarbeiter.vorname +
              " " +
              this.angemeldeterMitarbeiter.mitarbeiter.nachname,
            selectedPair: this.pair.pair,
            selectedDays: this.max,
            time: new Date().getTime(),
            tblRow: "",
            actionType: "",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    details(item) {
      Object.entries(this.allOpenRows).map((ele) => {
        if (ele.id !== item.id && ele._showDetails) {
          this.$set(ele, "_showDetails", !ele._showDetails);
        }
      });

      this.allOpenRows = {};
      this.$set(item, "_showDetails", !item._showDetails);
      let test = [];
      item.dates.forEach((element) => {
        test.push(element);
      });
      this.allOpenRows[item.symbol] = test;
    },

    getIEDetailColor(value) {
      if (value && value > 10.0) {
        return "table-success";
      } else {
        return "table-arvea-medium";
      }
    },
    getGreen(value) {
      if (value && value >= 1.5) {
        return "table-success";
      } else {
        return "table-arvea-medium";
      }
    },

    listGetGreen(value) {
      if (value && value >= 1.5) {
        return "table-success";
      } else {
        return "";
      }
    },

    formatNum(num) {
      if (num != null) {
        if (typeof num == "number") {
          var str = num.toLocaleString("en-US");
          str = str.replace(/\./, ".");
          str = str.replace(/,/g, "'");
          return str;
        } else return num;
      } else {
        return "-";
      }
    },

    setGraphData(input) {
      //TODO: Sollte eigentlich auf ID abgeglichen werden und nicht auf pair String
      this.pair = this.list.find((li) => li.pair == input);
    },

    sortDates(datumA, datumB, key) {
      if (key != "date") {
        return false;
      } else {
        let a = null;
        let b = null;

        if (key.includes(".")) {
          a = this.resolve(key, datumA);
          b = this.resolve(key, datumB);
        } else {
          a = datumA[key];
          b = datumB[key];
        }
        if (a && b) {
          a = a.split(".");
          b = b.split(".");
          a =
            parseInt(a[2], 10) * 10000 +
            parseInt(a[1], 10) * 100 +
            parseInt(a[0]);
          b =
            parseInt(b[2], 10) * 10000 +
            parseInt(b[1], 10) * 100 +
            parseInt(b[0]);
          return a - b;
        } else {
          return false;
        }
      }
    },
    /*** Methoden für StrikeEvolutionGraph ***/
    /*showStrikeEvolutions() {
      this.isBusy = true;
      this.strikes = [];
      //this.toggleDetails(row);

      // Bedingung ändern auf wenn Type == indices

      // jeweils das Datum vom Vortag (nur MO - FR)
      let lastWeekday = new Date();
      lastWeekday.setDate(
        lastWeekday.getDate() -
          (lastWeekday.getDay() === 6 ? 2 : lastWeekday.getDay() > 1 ? 1 : 3) // 3 Tage zurück wenn MO, 2 Tage zurück für SO, ansonsten 1
      );
      lastWeekday.toDateString();

      // Format yyyy-MM-dd
      let dateOfLastWeekday = [
        lastWeekday.getFullYear(),
        ("0" + (lastWeekday.getMonth() + 1)).slice(-2),
        ("0" + lastWeekday.getDate()).slice(-2),
      ].join("-");
/*
      Arvea.get("strikeevolution/", {
        params: { id: this.pair.symbol1_id, date: dateOfLastWeekday },
      })
        .then((response) => {
          this.strikes[0] = response.data.content;
          this.setYMaxAndMinStrikePrice(this.strikes[0]);
          this.counter++;
          this.updateKey = response.data.content[0].symbol + `${this.counter}`;
        })
        .catch(() => {})
        .finally(() => {
          this.isBusy = false;
        });*/

      /*Arvea.get("strikeevolution/", {
        params: { id: this.pair.symbol2_id, date: dateOfLastWeekday },
      })
        .then((response) => {
          this.strikes[1] = response.data.content;
          this.setYMaxAndMinStrikePrice(this.strikes[1]);
          this.counter++;
          this.updateKey = response.data.content[0].symbol + `${this.counter}`;
        })
        .catch(() => {})
        .finally(() => {
          this.isBusy = false;
        });
    },*/

    /*  toggleDetails(row) {
      if (row.item._showDetails) {
        this.$set(row.item, "_showDetails", false);
      } else {
        this.evolutionPerGrowthCycle.forEach((item) => {
          this.$set(item, "_showDetails", false);
        });

        this.$set(row.item, "_showDetails", true);
      }
    }, */
    setYMaxAndMinStrikePrice(strikes) {
      let maxSP = 0;
      let minSP = 99999;

      strikes.forEach((strike, index) => {
        if (strike.volume && strike.oi) {
          if (strike.volume > maxSP || strike.oi > maxSP) {
            maxSP = strike.volume > strike.oi ? strike.volume : strike.oi;
          }
          if (strike.volume < minSP || strike.oi < minSP) {
            minSP = strike.volume < strike.oi ? strike.volume : strike.oi;
          }
        }

        // Zuordnung der min max Werte mit etwas Abstand zum Boden/Decke
        // -> im letzten Element des Arrays sind die endgültigen max und min Werte, die für die Darstellung an das StrikeEvolutionGraph component übergeben werden
        strikes[index].yMaxStrikePrice = Math.ceil(maxSP * 1.1);
        strikes[index].yMinStrikePrice = Math.floor(minSP * 0.9);
      });
    },
    handleNewPair(input) {
      this.setGraphData(input);
      this.trackSelects("pairs");
    },
    trackSelects(origin) {
      this.clicks.push({
        user_ID: this.angemeldeterMitarbeiter.mitarbeiter.id,
        user_name:
          this.angemeldeterMitarbeiter.mitarbeiter.vorname +
          " " +
          this.angemeldeterMitarbeiter.mitarbeiter.nachname,
        selectedPair: this.pair.pair,
        selectedDays: this.max,
        time: new Date().getTime(),
        tblRow: "",
        actionType: origin === "days" ? "selection days" : "selection pairs",
      });
    },
    // sendingLastTime should be true when sending the last request as the page is unloaded. Should be able to be sent with only 1 Click in this.clicks
    sendClicks(sendingLastTime) {
      if (!this.isExtern) return;
      if (this.clicks.length >= 2 || sendingLastTime) {
        this.clickPost();
      } else return; // No clicks to send
    },
    clickPost() {
      Arvea.post("clicktracker/", this.clicks)
        .then(() => {
          // fill clicks with initial options to campare with next Userclick
          this.clicks = [
            {
              user_ID: this.angemeldeterMitarbeiter.mitarbeiter.id,
              user_name:
                this.angemeldeterMitarbeiter.mitarbeiter.vorname +
                " " +
                this.angemeldeterMitarbeiter.mitarbeiter.nachname,
              selectedPair: this.pair.pair,
              selectedDays: this.max,
              time: new Date().getTime(),
              tblRow: "",
              actionType: "",
            },
          ];
        })
        .catch((e) => {
          console.error(e);
        });
    },

    handleRowClicked(item, index, event) {
      this.details(item);
      const rowIndex = this.expandedRows.indexOf(index);

      if (rowIndex > -1) {
        // Row is already expanded, collapse it
        this.expandedRows.splice(rowIndex, 1);
        this.clicks.push({
          user_ID: this.angemeldeterMitarbeiter.mitarbeiter.id,
          user_name:
            this.angemeldeterMitarbeiter.mitarbeiter.vorname +
            " " +
            this.angemeldeterMitarbeiter.mitarbeiter.nachname,
          selectedPair: this.pair.pair,
          selectedDays: this.max,
          time: new Date().getTime(),
          tblRow: item.pair,
          actionType: "table row closed",
        });
        return;
      } else {
        // Expand the clicked row
        this.expandedRows.push(index);
        this.clicks.push({
          user_ID: this.angemeldeterMitarbeiter.mitarbeiter.id,
          user_name:
            this.angemeldeterMitarbeiter.mitarbeiter.vorname +
            " " +
            this.angemeldeterMitarbeiter.mitarbeiter.nachname,
          selectedPair: this.pair.pair,
          selectedDays: this.max,
          time: new Date().getTime(),
          tblRow: item.pair,
          actionType: "table row opened",
        });
      }
    },
    handleTableSorted({ column, order }) {
      console.log("Table sorted:", column, order);
    },
  },
};
</script>
<style lang="scss">
body {
  background: white;
  color: #213056;
  overflow-x: hidden;
}
#etf .scrollable-table {
  height: 500px;
  overflow-y: scroll;
}
#etf .scrollable-details-table {
  height: 380px;
  overflow-y: scroll;
}

#etf .banner {
  background-color: #e6edff;
  height: 300px;
  width: 100vw;
}
#etf .banner h1 {
  padding-top: 65px;
  font-weight: 700;
  color: #c7cdde;
}
#etf .banner p {
  padding-top: 10px;
  font-size: 16px;
  padding-right: 30%;
}
#etf .banner > div:last-child {
  display: flex;
  align-items: center;
}
#etf .etfGraph_graph {
  margin-top: 100px;
}
#etf th {
  cursor: pointer;
}
#etf .etfTable {
  cursor: pointer;
}
#etf .detailTable {
  cursor: default;
}
#etf .etfDropdown {
  position: relative;
  max-width: 75%;
}
#etf .title h1 {
  font-size: 36px;
}
#etf .title span {
  font-size: 17px;
}
#etf .etfGraphRow {
  margin-top: 80px;
}
#etf .etfInfos {
  margin-top: 70px;
  padding: 50px 50px;
  background: #213056;
  border-radius: 10px;
  max-width: 75%;
  color: white;
}
#etf .etfSymbols {
  font-size: 30px;
  font-weight: bold;
}
#etf .etfDates {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.05rem;
}
#etf .strikeEvolutionGraphs {
}
/*
tr:nth-child(odd) {
  background-color: #c6ccdd;
}

tr:nth-child(even) {
  background-color: white;
}

th {
  background-color: white;
}

tr[th] {
  margin-bottom: 20px;
}

.detailTable tr:nth-child(odd) {
  background-color: #9099b0;
}

.detailTable tr:nth-child(even) {
  background-color: rgb(255, 255, 255);
}

.detailTable th {
  background-color: #c6ccdd;
}

.table-hover tbody tr:hover,
.table-hover tbody tr:hover {
  background-color: #8f98ae;
}

.detailTable .table-hover tbody tr:hover,
.detailTable .table-hover tbody tr:hover {
  background-color: yellow;
}*/
</style>
